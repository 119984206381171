import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//const store = useSelector(state => state.auth)
//const userData = store.userData || null
//const existingAbility = userData ? userData.ability : null
//as this context starts upon app opening there cannot be any ability, it's set up later by token refresher or login form
const existingAbility = false

export default new Ability(existingAbility || initialAbility)
