import React from 'react'

import MainErrorFallback from "@src/views/MainErrorFallback"

class MainErrorBoundary extends React.Component {
  state = {
    error: null
  }

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error }
  }

  componentDidCatch(error, info) {
    // Log the error to an error reporting service
    console.log(`catcheded!`)
    console.log(error, info)
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <MainErrorFallback errorText={JSON.stringify(this.state.error)}/>
    }
    return this.props.children
  }
}

export default MainErrorBoundary