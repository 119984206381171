import {cloneDeep} from 'lodash'

/**
 *
 * @param arr
 * @return {*}
 */
const mapBuilder = arr => arr.reduce((acc, cur, indx) => {
  return Object.assign(acc, {[cur.msgUUID]: indx })
}, {})

// ** Initial State
const initialState = {
  mails: [],
  canFetchMoreMails: true,
  params: {},
  currentMail: null,
  //fancyMap contains a sort of a hashmap that contains msgIDs and array indexes to help find the required element in mail array
  //this is basically MySQL indexing without extra steps
  fancyMap: {},
  //appMode can be mailList, mailSelected, composing...
  appMode: 'mailList',
  emailsMeta: {},
  selectedMails: [],
  mailComponentLoading: false,
  moreMailsLoading: false,
  selectedMailLoading: false,
  updateMailLoading: false,
  readReceiptLoading: false,
  sendEMessageReqStatus: 'idle',
  mailDeleteReqStatus: 'idle'
}

const EmailReducer = (state = initialState, action) => {

  let processedMap, updatedArray, indx, newCurrentMsg

  switch (action.type) {
    case 'GET_MAILS/DONE':
      // ** If currentMail is not null / undefined then find and set currentMail
      processedMap = mapBuilder(action.data)
      return {
        ...state,
        mails: action.data,
        canFetchMoreMails: action.data.length !== 0,
        fancyMap: processedMap,
        params: action.params,
        currentMail: null,
        mailComponentLoading: false
      }
    case 'GET_MAILS/PENDING':
      return {
        ...state,
        mailComponentLoading: true
      }
    case 'GET_MAILS/ERROR':
      return {
        ...state,
        canFetchMoreMails: false,
        mailComponentLoading: false
      }
    case 'GET_MORE_MAILS/DONE':
      //as a symptom of pagination system at least 1 mail will repeat, those need to be eliminated
      action.data = action.data.filter(entry => {
        //hashMap can be used to check if element exists
        return !(entry.msgUUID in state.fancyMap)
      })
      // determine if more mails have been loaded and any work is required
      if (action.data.length === 0) return {...state, moreMailsLoading: false, canFetchMoreMails: false}

      updatedArray = [...cloneDeep(state.mails), ...action.data]

      //same thing as with initial load + stitch to old map
      processedMap = mapBuilder(updatedArray)
      //@todo possible optimization: process only new entries and stitch old + new map

      return {
        ...state,
        mails: updatedArray,
        canFetchMoreMails: action.data.length !== 0,
        fancyMap: processedMap,
        moreMailsLoading: false
      }
    case 'GET_MORE_MAILS/PENDING':
      return {
        ...state,
        moreMailsLoading: true
      }
    case 'GET_MORE_MAILS/ERROR':
      return {
        ...state,
        canFetchMoreMails: false,
        moreMailsLoading: false
      }
    case 'DESELECT_CURRENT_MAIL':
      return {...state, currentMail: null}
    case 'SELECT_CURRENT_MAIL/DONE':
      //toggle isOpened in the mails (backend does it on it's own)
      updatedArray = state.mails
      try {
        indx = state.mails[state.fancyMap[action.mail.msgUUID]]
        if (!indx.isOpened) {
          updatedArray = cloneDeep(state.mails)
          updatedArray[state.fancyMap[action.mail.msgUUID]].isOpened = true
        }
      } catch (e) {
        //@todo toast?
        console.log(`wow! mail ${action.mail.msgUUID} just disappeared!`)
        console.log(e)
      }
      return { ...state, currentMail: action.mail, mails: updatedArray, selectedMailLoading: false}
    case 'SELECT_CURRENT_MAIL/PENDING':
      return {...state, selectedMailLoading: true}
    case 'SELECT_CURRENT_MAIL/ERROR':
      return {...state, selectedMailLoading: false}
    case 'PROCESS_READ_RECEIPT/PENDING':
      return {...state, readReceiptLoading: true}
    case 'PROCESS_READ_RECEIPT/ERROR':
      return {...state, readReceiptLoading: false}
    case 'PROCESS_READ_RECEIPT/DONE':
      updatedArray = state.mails
      //this is the index of message in state's array, no more lookups
      indx = state.fancyMap[action.newReceipt.emailID]
      //if emailID exists in hashmap then array needs 2B updated
      if (indx || indx === 0) {
        //mock data instead of queering backend
        updatedArray = cloneDeep(state.mails)
        updatedArray[indx].isConfirmed = true
      }
      newCurrentMsg = state.currentMail
      //it may be possible that interface will only load THIS message, thus redux can't rely on current message being a reference to array of mails
      if (newCurrentMsg.msgUUID === action.newReceipt.emailID) {
        newCurrentMsg = cloneDeep(state.currentMail)
        newCurrentMsg.isConfirmed = true
        if (newCurrentMsg.readReceipts) {
          newCurrentMsg.readReceipts.forEach(rReceipt => {
            if (rReceipt.receiptUUID === action.newReceipt.newUUID) rReceipt.tstampConfirmed = new Date().getTime().toString(10)
          })
        }
      }
      return {...state, mails: updatedArray, currentMail: newCurrentMsg, readReceiptLoading: false}
    case 'CHANGE_APP_MODE':
      return {...state, appMode: action.appMode}
    case 'SEND_EMESSAGE/PENDING':
      return {...state, sendEMessageReqStatus: action.reqStatus}
    case 'SEND_EMESSAGE/DONE':
      return {...state, sendEMessageReqStatus: action.reqStatus}
    case 'SEND_EMESSAGE/ERROR':
      return {...state, sendEMessageReqStatus: action.reqStatus}
    case 'SEND_EMESSAGE/IDLE':
      return {...state, sendEMessageReqStatus: action.reqStatus}
    case 'DELETE_EMESSAGE/PENDING':
      return {...state, mailDeleteReqStatus: action.reqStatus}
    case 'DELETE_EMESSAGE/ERROR':
      return {...state, mailDeleteReqStatus: action.reqStatus}
    case 'DELETE_EMESSAGE/DONE':
      //it's assumed that message might be already in state, so it has to be mutated as well
      updatedArray = state.mails
      processedMap = state.fancyMap
      if (action.msgUUID in state.fancyMap) {
        //deletes 1 message
        indx = state.fancyMap[action.msgUUID]
        updatedArray = cloneDeep(state.mails)
        updatedArray.splice(indx, 1)
        //@todo regenerating the entire map is simple but redundant, there might be a way to further optimize it
        processedMap = mapBuilder(updatedArray)
      }
      return {...state, mails: updatedArray, fancyMap: processedMap, mailDeleteReqStatus: action.reqStatus}
    case 'DELETE_EMESSAGE/IDLE':
      return {...state, mailDeleteReqStatus: action.reqStatus}
    case 'SET_MSG_ARCHIVED/DONE':
      indx = state.fancyMap[action.msgUUID]
      updatedArray = cloneDeep(state.mails)
      newCurrentMsg = cloneDeep(state.currentMail)
      newCurrentMsg.isArchived = action.archived
      updatedArray[indx].isArchived = action.archived
      return {...state, mails: updatedArray, currentMail: newCurrentMsg}
    default:
      return state
  }
}

export default EmailReducer
