// ** Imports createContext function
import { createContext } from 'react'

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react'

// ** Create Context
export const AbilityContext = createContext()

// ** Init Can Context
export const Can = createContextualCan(AbilityContext.Consumer)

const apiToACL = {
  askConge: {
    action: 'create',
    subject: 'ownVacance'
  },
  editOwnConge: {
    action: 'edit',
    subject: 'ownVacance'
  },
  editAnyConge: {
    action: 'edit',
    subject: 'anyVacance'
  },
  deleteOwnConge: {
    action: 'delete',
    subject: 'ownVacance'
  },
  deleteAnyConge: {
    action: 'delete',
    subject: 'anyVacance'
  },
  showOwnConge: {
    action: 'access',
    subject: 'ownVacance'
  },
  showAnyConge: {
    action: 'access',
    subject: 'anyVacance'
  },
  archivedConges: {
    action: 'access',
    subject: 'archivedVacances'
  },
  editCongeStatus: {
    action: 'edit',
    subject: 'vacanceStatus'
  },
  showOwnPlanning: {
    action: 'access',
    subject: 'ownPlanning'
  },
  showAnyPlanning: {
    action: 'access',
    subject: 'anyPlanning'
  },
  accessStandoCongePage: {
    action: 'access',
    subject: 'myVacancesPage'
  },
  accessStandoCongeModPage: {
    action: 'access',
    subject: 'modVacancesPage'
  },
  accessStandoDashboardPage: {
    action: 'access',
    subject: 'dashboardPage'
  },
  accessStandoPlanningPage: {
    action: 'access',
    subject: 'planningPage'
  },
  accessStandoAllPlannings: {
    action: 'access',
    subject: 'allPlanningsPages'
  },
  accessStandoMessenger: {
    action: 'access',
    subject: 'messengerPage'
  },
  checkEMessageReadReceipts: {
    action: 'access',
    subject: 'eMessageReadReceipts'
  },
  deleteEMessages: {
    action: 'delete',
    subject: 'eMessages'
  },
  deleteEMessageBroadcasts: {
    action: 'delete',
    subject: 'eMessageBroadcasts'
  },
  readEMessages: {
    action: 'access',
    subject: 'ownEMessages'
  },
  sendEMessages: {
    action: 'send',
    subject: 'eMessages'
  },
  sendEBroadcasts: {
    action: 'send',
    subject: 'eMessageBroadcasts'
  },
  eMessengerRoot: {
    action: 'access',
    subject: 'eMessengerRoot'
  }
}

export const convertRights = (rights) => {
  //@todo parsing rights from api response
  /**
   * Api replies with following pattern:
   * [{id: Int, description: String}]
   */
    //login page as well as menu can be accessed by anyone
  let buf = [{ action: 'read', subject: 'Auth'}, {action: 'access', subject: 'Menu'}]
  //console.log(`ConvertRights Called, converting this:`)
  //console.log(rights)
  rights.forEach(right => {
    if (apiToACL[right.description]) {
      let obj = {}
      Object.assign(obj, apiToACL[right.description])
      buf = [...buf, obj]
    }
  })
  //console.log(`To this:`)
  //console.log(buf)

  return buf
  /* this is root access:
  return [
      {
          action: 'manage',
          subject: 'all'
      }
  ]*/
}
