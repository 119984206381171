// ** Initial State
const initialState = {
  userVacations: [],
  lastDemand: null,
  getDataReqStatus: 'idle',
  reqError: null,
  askVacationReqStatus: 'idle',
  cancelRequestReqStatus: 'idle'
}

const vacations = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA/DONE':
      return {
        ...state,
        userVacations: action.data.userVacations,
        lastDemand: action.data.userVacations?.[0] || null,
        getDataReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'GET_DATA/PENDING':
      return {
        ...state,
        userVacations: null,
        lastDemand: null,
        getDataReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'GET_DATA/ERROR':
      return {
        ...state,
        userVacations: null,
        lastDemand: null,
        getDataReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'ASK_VACATION/PENDING':
      return {
        ...state,
        askVacationReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'ASK_VACATION/ERROR':
      return {
        ...state,
        askVacationReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'ASK_VACATION/DONE':
      return {
        ...state,
        userVacations: [action.newRequest, ...state.userVacations],
        lastDemand: action.newRequest,
        askVacationReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'CANCEL_VACATION_REQUEST/DONE':
      let newVacationList = [...state.userVacations]
        //del 1st el
      newVacationList.shift()
      return {
        ...state,
        userVacations: newVacationList,
        lastDemand: newVacationList?.[0] || null,
        cancelRequestReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'CANCEL_VACATION_REQUEST/PENDING':
      return {
        ...state,
        cancelRequestReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'CANCEL_VACATION_REQUEST/ERROR':
      return {
        ...state,
        cancelRequestReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    default:
      return { ...state }
  }
}
export default vacations
