import React, {useContext, useEffect} from 'react'

// ** JWT
import JWTinstance from '@src/auth/jwt/useJwt'
import {initialAbility} from '@src/configs/acl/initialAbility'
import { useSelector } from 'react-redux'
import {AbilityContext} from "./utility/context/Can"

let myPromise = JWTinstance.refreshToken()
import {updateGQLToken} from '@src/graphql/client.js'

const Auth = ({ children }) => {
  const store = useSelector(state => state.auth, () => true)

  const ability = useContext(AbilityContext)

  let refreshable = JWTinstance.refreshable
  let accessToken = JWTinstance.getToken()
  //console.log(`accessToken: ${accessToken}, refreshable: ${refreshable}`)
  //if still no token try to get it from refresher
  if (!accessToken && refreshable) {
    //console.log(`No token, getting info from tokenFetcher: `)
    throw myPromise
  } else {
    //update context for ability if there is smth
    if (store.userData && store.userData.ability) {
      updateGQLToken(store.accessToken)
      ability.update(store.userData.ability)
    } else {
      updateGQLToken(store.accessToken)
      ability.update(initialAbility)
    }
    return <>{ children }</>
  }

}

export default Auth
