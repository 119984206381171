/**
 * Important: every vacation has processing attr, that does not allow to start 2 concurrent actions for 1 entry
 * Since redux really needs to clone the entire array for every modification optimization is just bad
 */
const initialState = {
  vacations: [],
  getDataReqStatus: 'idle',
  availableUsers: [],
  reqError: null,
  filters: {
    showArchived: false,
    showRejected: true,
    showValidated: true,
    showPending: true,
    filteredUsers: []
  }
}

const buildAvailableUsers = (vacations) => {
  //this ensures uniqueness of every user
  let userMap = new Map(vacations.map(entr => [entr.userID, entr.userName]))
  //formats for react-select
  let arr = []
  for (const [key, value] of userMap.entries()) {
    arr.push({value: key, label: value})
  }
  return arr
}

const modVacations = (state = initialState, action) => {
  let newVacations
  switch (action.type) {
    case 'GET_MOD_VACATION_DATA/DONE':
      return {
        ...state,
        vacations: action.data.vacations || state.vacations,
        availableUsers: buildAvailableUsers(action.data.vacations),
        getDataReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'GET_MOD_VACATION_DATA/ERROR':
      return {
        ...state,
        vacations: [],
        getDataReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'GET_MOD_VACATION_DATA/PENDING':
      return {
        ...state,
        vacations: [],
        getDataReqStatus: action.reqStatus,
        reqError: action.reqError || null
      }
    case 'CHANGE_VACATION_STATUS/DONE':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = false
      newVacations[action.data.index].status = action.data.newStatus
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'CHANGE_VACATION_STATUS/PENDING':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = true
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'CHANGE_VACATION_STATUS/ERROR':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = false
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'DELETE_VACATION/DONE':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations.splice(action.data.index, 1)
      return {
        ...state,
        availableUsers: buildAvailableUsers(newVacations),
        vacations: [...newVacations]
      }
    case 'DELETE_VACATION/PENDING':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = true
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'DELETE_VACATION/ERROR':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = false
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'CHANGE_VACATION_ARCHIVED/DONE':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = false
      newVacations[action.data.index].isArchived = action.data.isArchived
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'CHANGE_VACATION_ARCHIVED/PENDING':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = true
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'CHANGE_VACATION_ARCHIVED/ERROR':
      newVacations = state.vacations.map((a => ({...a})))
      newVacations[action.data.index].processing = false
      return {
        ...state,
        vacations: [...newVacations]
      }
    case 'SWITCH_ARCHIVED_FILTER':
      return {...state,
        filters: {...state.filters, showArchived: action.data.newValue}
      }
    case 'SWITCH_REJECTED_FILTER':
      return {...state,
        filters: {...state.filters, showRejected: action.data.newValue}
      }
    case 'SWITCH_VALIDATED_FILTER':
      return {...state,
        filters: {...state.filters, showValidated: action.data.newValue}
      }
    case 'SWITCH_PENDING_FILTER':
      return {...state,
        filters: {...state.filters, showPending: action.data.newValue}
      }
    case 'UPDATE_USER_FILTER':
      return {...state,
        filters: {...state.filters, filteredUsers: action.data.newValue}
      }
    default:
      return { ...state }
  }
}
export default modVacations
