// ** UseJWT import to get config
import JWTInstance from '@src/auth/jwt/useJwt'
import {updateGQLToken} from '@src/graphql/client.js'

//const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = data => {
  return dispatch => {
    updateGQLToken(data.accessToken)
    dispatch({
      type: 'LOGIN',
      data,
      accessToken: data.accessToken
    })
  }
}

// ** Handle User Logout
export const handleLogout = () => {
  return dispatch => {
    JWTInstance.terminateRefreshToken()
    updateGQLToken(``)
    dispatch({ type: 'LOGOUT', accessToken: null})
  }
}
