import {GraphQLClient} from "graphql-request/dist"
import JWTinstance from '@src/auth/jwt/useJwt'

//console.log(`engaging gql client`)
const endpoint = `${process.env.REACT_APP_API_LINK}/graphql`
const graphQLClient = new GraphQLClient(endpoint)
/*
if (JWTinstance?.accessToken) {
  graphQLClient.setHeader('authorization', JWTinstance.accessToken)
  console.log(`gql client now has a header with access token!`)
}
*/

export const updateGQLToken = (token) => {
  //console.log(`gql client now has an updated token`)
  graphQLClient.setHeader('authorization', JWTinstance.accessToken)
}

export default graphQLClient
