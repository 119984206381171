// ** Auth Endpoints
export default {
    //need full link somehow
    loginMutationName: 'login',
    refreshEndpoint: '/refresher',
    loginEndpoint: '/login',
    logoutEndpoint: '/logout',
    terminatorEndpoint: '/terminator',
    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    authHeader: 'Authorization',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken'
}
