// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import vacations from '@src/views/simples/askVacance/store/reducer'
import modVacations from '@src/views/simples/modVacance/store/reducer'
import messenger from '@src/views/complex/messenger/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  vacations,
  modVacations,
  messenger
})

export default rootReducer
