//axios may be used as crude method to access Graphql for now
//@todo create an apollo client instance to interact with Graphql
import axios from 'axios'
import jwtDefaultConfig from './jwtConfig'
import {store} from '@src/redux/storeConfig/store.js'
import { handleLogin } from '@store/actions/auth'
import { convertRights } from '@src/utility/context/Can'

export default class JwtService {
  /* eslint-disable */
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }
  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false
  // ** Stitch endpoints for further use
  //apiLink = process.env.REACT_APP_API_LINK + "/graphql"
  loginerLink = process.env.REACT_APP_LOGINER_LINK + jwtDefaultConfig.loginEndpoint
  /* eslint-enable */
  refresherLink = process.env.REACT_APP_LOGINER_LINK + jwtDefaultConfig.refreshEndpoint
  terminatorLink = process.env.REACT_APP_LOGINER_LINK + jwtDefaultConfig.terminatorEndpoint
  // ** For Refreshing Token
  subscribers = []
  ability = null
  // ** Redux store to access JWT from it
  state = store.getState();

  // ** Start with empty token
  accessToken = ''
  // ** Assume token can be refreshed on startup
  refreshable = true
  // ** Assume token is already expired
  tokenExp = new Date().getTime()

  newRefreshTimer = null

  constructor(jwtOverrideConfig) {
    //console.log(`DEBUG: JWT constructor run`)
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    //try to load access token from state if it exists (somehow)
    this.accessToken = this.state.auth.userData[this.jwtConfig.storageTokenKeyName]
    //if (!this.accessToken) console.log(`DEBUG: App has no accessToken in memory or Redux`)
    //else console.log(`DEBUG: App has restored accessToken from memory or Redux`)
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  isUserLoggedIn() {
    return this.accessToken && (this.tokenExp > new Date().getTime())
  }

  getToken() {
    return this.accessToken
  }

  parseJwt (token) {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return null
    }
  }

  //will dispatch the new userAuth into Redux
  setAuthData(authData) {
    let parsed = this.parseJwt(authData.accessToken)
    if (parsed) {
      let now = new Date().getTime()
      authData.exp = parsed.exp * 1000 || now
      //console.log(`Token will expire in ${authData.exp - now}ms`)
      this.refreshable = true
      this.accessToken = authData.accessToken || null
      this.tokenExp = authData.exp
      //i guess 20 extra seconds is good enough
      let refreshIn = this.tokenExp - now - 20000
      if (refreshIn > 0) {
        //console.log(`Starting timer for T-${refreshIn}ms for refresh attempt`)
        this.newRefreshTimer = setTimeout(() => { this.refreshToken() }, refreshIn)
      }
      this.ability = authData.ability || null
      store.dispatch(handleLogin(authData))
    }
  }
/*
  refreshTokens() {
    if (this.refreshable) {
      //console.log(`will try to refresh token`)
      this.refreshToken()
    }
  }
  */

  login(login, password) {
    const body = {login, pass: password}
    return axios.post(this.loginerLink, body, {withCredentials: true})
  }


  refreshToken() {
    return axios.post(
      this.refresherLink,
      {},
      {withCredentials: true, timeout: 1000, retries: 2}
    )
      .then(res => {
        if (res.data.data && !res.data.errors) {
          if (!res.data.data.accessToken) {
            console.log(`Api forgot to send accessToken, app won't work...`)
            this.refreshable = false
          }
          //console.log(`Token rebuilt from refresh token : ${res.data.data.accessToken}`)
          res.data.data.ability = convertRights(res.data.data.permissions || [])
          this.setAuthData(res.data.data)
        } else {
          //console.log(`Unable to rebuild the access token from refresh token...`)
          this.refreshable = false
        }
      })
      .catch(err => {
        console.log(err)
        this.refreshable = false
      })
  }

  //deletes the refresh cookie, promise is not handled properly, might be an issue later
  terminateRefreshToken() {
    //console.log(`Terminating refresh token`)
    this.accessToken = ``
    this.refreshable = false
    //stop planned refreshes
    if (this.newRefreshTimer) clearTimeout(this.newRefreshTimer)
    //set date to now so it's sort of expired
    this.tokenExp = new Date().getTime()
    axios.post(
      this.terminatorLink,
      {},
      {withCredentials: true, timeout: 3000, retries: 1}
    )
      .then(res => {
        if (res.data === `nothing to terminate`) console.log(`Something is wrong with refresh token termination`)
        //else console.log(`Server has terminated the refresh token`)
      })
      .catch(e => {
        //console.log(`Something is wrong with refresh token termination`)
        console.log(e)
      })
  }
}
